import React from 'react'

const OffsetGridWrapper = ({ title, children }) => (
  <div className="bg-white overflow-hidden">
    <div className="relative max-w-screen-xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <svg className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
        <defs>
          <pattern id="svg-pattern-squares-1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#svg-pattern-squares-1)" />
      </svg>

      <div className="relative lg:grid lg:grid-cols-3 lg:col-gap-12">
        <div className="lg:col-span-1">
          <h3 className="text-3xl leading-9 lg:pr-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            {title}
          </h3>
        </div>
        <div className="mt-10 sm:grid sm:grid-cols-2 sm:col-gap-12 sm:row-gap-12 lg:col-span-2 lg:mt-0">
          {children}
        </div>
      </div>
    </div>
  </div>
)

export default OffsetGridWrapper