import React from 'react'
import PropTypes from 'prop-types'

const GridItemWithIcon = ({ title, description, icon, link }) => (
  <div className="mt-10 first:mt-0 sm:mt-0">
    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
      {icon}
    </div>
    <div className="mt-5">
      <h5 className="text-lg leading-6 font-semibold text-gray-900">{title}</h5>
      {description && <p className="mt-2 text-base leading-6 text-gray-500">
        {description}
      </p>}
      {link && link}
    </div>
  </div>
)

export default GridItemWithIcon

GridItemWithIcon.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.element.isRequired,
  link: PropTypes.element
}