import React from 'react'

import Layout from '../../components/Layout'
import { HeroSimpleCentered } from '../../components/Global/Hero'
import { YellowButton, BlackButton } from '../../components/Global/Button'
import { Link, useStaticQuery, graphql } from 'gatsby'
import SEO from '../../components/Global/SEO'
import { OffsetGridWrapper, GridItemWithIcon } from '../../components/Global/Content/OffsetGrid'
import { GlobeAlt, SwitchHorizontal, LocationMarker, LockClosed, Briefcase, Heart } from '../../components/Global/Icons'

const ServicesPage = ({ location }) => {

  const { seoImg } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "chauffeur-me-van-at-titanic-belfast.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
    }
  `)

  return (
    <Layout>

      <SEO
        title="Professional Chauffeur Services | Airport Transfers, Golf Trips, Corporate Travel, Weddings"
        description="Whether you’re looking for luxurious travel to a special event, business travel which includes some highly productive time, or glamorous transport for a special day, Chauffeur Me can provide it."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />
      
      <HeroSimpleCentered>
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          Belfast
          <br className="xl:hidden" />
          <span className="text-yellow-300"> Chauffeur Services</span>
        </h2>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
        Luxury, first class chauffeur services with chauffeur Me, belfast. Whether you need transportation for a special event, productive business travel or a glamorous ride for a special day, we can provide it all. Our team is dedicated to providing the highest level of service, comfort and luxury for all your transportation needs. Book your reservation today and experience the best in first class chauffeur services.
        </p>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <YellowButton to="/book-now" className="flex items-center justify-center rounded-sm shadow sm:mr-2 mb-4 sm:mb-0">Book Now</YellowButton>
          <BlackButton to="/our-fleet" className="flex items-center justify-center rounded-sm shadow sm:ml-2">Our Fleet</BlackButton>
        </div>
      </HeroSimpleCentered>

      <OffsetGridWrapper title="Chauffeur Services You Can Rely On">
        <GridItemWithIcon
          title="Golf Trips"
          description="Play some of Ireland's world famous golf courses with our chauffeur driven golf trips, tailored to suit your needs."
          icon={<GlobeAlt />}
          link={<Link to="/services/chauffeur-driven-golf-trips" title="Find out more about Chauffeur Driven Golf Trips" className="block mt-2 text-yellow-400 underline hover:text-yellow-300 hover:no-underline ease-in-out transition-all duration-50">Learn More &rarr;</Link>}
        />
        <GridItemWithIcon
          title="Airport Transfers"
          description="Executive transport services from any of Belfast International, Belfast City or Dublin International airports to anywhere in Ireland."
          icon={<SwitchHorizontal />}
          link={<Link to="/services/executive-airport-transfers" title="Find out more about Executive Airport Transfers" className="block mt-2 text-yellow-400 underline hover:text-yellow-300 hover:no-underline ease-in-out transition-all duration-50">Learn More &rarr;</Link>}
        />
        <GridItemWithIcon
          title="Cruise Ship Excursions"
          description="Belfast is a popular stop-off for cruise ships and Chauffeur Me offer day tours to suit the sailing times of visiting ships."
          icon={<LocationMarker />}
          link={<Link to="/services/luxury-cruise-ship-excursions" title="Find out more about Cruise Ship Excursions" className="block mt-2 text-yellow-400 underline hover:text-yellow-300 hover:no-underline ease-in-out transition-all duration-50">Learn More &rarr;</Link>}
        />
        <GridItemWithIcon
          title="Private Tours"
          description="Take a private tour of Ireland in one of our luxury vehicles and discover some of Ireland's world famous locations."
          icon={<LockClosed />}
          link={<Link to="/tours" title="Find out more about Private Chauffeured Tours" className="block mt-2 text-yellow-400 underline hover:text-yellow-300 hover:no-underline ease-in-out transition-all duration-50">Learn More &rarr;</Link>}
        />
        <GridItemWithIcon
          title="Corporate Travel"
          description="Corporate chauffeur services that deliver a high caliber, personalised service with meticulous attention to detail that business travellers expect."
          icon={<Briefcase />}
          link={<Link to="/services/corporate-travel" title="Find out more about Corporate Travel" className="block mt-2 text-yellow-400 underline hover:text-yellow-300 hover:no-underline ease-in-out transition-all duration-50">Learn More &rarr;</Link>}
        />
        <GridItemWithIcon
          title="Wedding Transport"
          description="A luxury fleet of cars for your wedding travel needs to help make your wedding day a very special day."
          icon={<Heart />}
          link={<Link to="/services/professional-wedding-chauffeur" title="Find out more about Wedding Transport" className="block mt-2 text-yellow-400 underline hover:text-yellow-300 hover:no-underline ease-in-out transition-all duration-50">Learn More &rarr;</Link>}
        />
      </OffsetGridWrapper>

    </Layout>
  )
}

export default ServicesPage