import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const GridItemWithImage = ({ title, description, sources, imageAlt }) => (
  <div className="mt-10 first:mt-0 sm:mt-0">
    <Img fluid={sources} className="rounded-md shadow-md" alt={imageAlt} />
    <div className="mt-5">
      <h4 className="text-xl leading-6 font-semibold text-gray-900">{title}</h4>
      {description && <p className="mt-1 text-base leading-6 text-gray-500">{description}</p>}
    </div>
  </div>
)

export default GridItemWithImage

GridItemWithImage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  sources: PropTypes.object.isRequired,
  imageAlt: PropTypes.string.isRequired
}